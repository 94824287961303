import { confirm, FontSizes } from "@bps/fluent-ui";
import { CopyableText } from "@components/CopyableText";
import React from "react";

export const pinConfirmation = (pin: string, subText?: string) => {
  return confirm({
    confirmButtonProps: {
      children: "Close",
      styles: { root: { alignSelf: "end" } }
    },
    cancelButtonProps: { styles: { root: { display: "none" } } },
    dialogContentProps: {
      subText:
        subText ??
        "A pin code is required to redeem this link. Record this pin code as it will not be shown again.",
      title: "Invitation Pin Code"
    },
    children: (
      <CopyableText
        clipboardContent={pin}
        styles={{ root: { fontSize: FontSizes.xxLarge } }}
        horizontalFill
        stackStyles={{ root: { justifyContent: "center" } }}
      >
        {pin}
      </CopyableText>
    )
  });
};
