import { FunctionComponent } from "react";
import { Dialog } from "@bps/fluent-ui";
import { Form } from "@components/form/Form";
import { AddCouponFormFields } from "./AddCouponFormFields";
import {
  AddCouponFormDialogProps,
  AddCouponFormValues
} from "./AddCouponFormDialog.types";
import { validatedCouponForm } from "./AddCouponFormDialog.validator";
import { useFieldArray, useFormContext } from "react-hook-form";
import { SubscriptionFormValues } from "../subscription-form/SubscriptionForm.types";

const defaultValues: AddCouponFormValues = {
  coupons: []
};

export const AddCouponFormDialog: FunctionComponent<AddCouponFormDialogProps> = ({
  excludePromotionCodeList,
  onConfirm,
  onDismiss
}) => {
  const { append } = useFieldArray({ name: "additionalPromotionCodeList" });
  const { getValues } = useFormContext<SubscriptionFormValues>();
  return (
    <Dialog
      hidden={false}
      dialogContentProps={{ title: "Add discount", showCloseButton: true }}
      minWidth={450}
      onDismiss={onDismiss}
    >
      <Form<AddCouponFormValues>
        defaultValues={defaultValues}
        onSubmit={async () => {}}
        validate={validatedCouponForm}
        hideButtons
      >
        <AddCouponFormFields
          onConfirm={coupons => {
            append(coupons);
            const values = getValues();
            onConfirm(values);
          }}
          excludePromotionCodeList={excludePromotionCodeList}
        />
      </Form>
    </Dialog>
  );
};
