import React, { FunctionComponent } from "react";
import { ActionButton, Stack } from "@bps/fluent-ui";
import { AddSubscriptionItemDialog } from "../add-subscription-item-dialog/AddSubscriptionItemDialog";
import { AddSubscriptionItemDialogValues } from "../add-subscription-item-dialog/AddSubscriptionItemDialog.types";
import { SubscriptionItemProducts } from "./SubscriptionItems.types";
import {
  useFieldArray,
  useFormContext,
  useFormState,
  useWatch
} from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  CustomerProductsSubscription,
  Tenant
} from "@libs/api/gateways/plt/plt-gateway.dtos";
import { useSalesProducts } from "@libs/api/gateways/plt/plt-gateway.hooks";
import { SubscriptionFormValues } from "./SubscriptionForm.types";
import { ApiCallButton } from "@components/buttons/ApiCallButton";
import { HttpError } from "@bps/http-client";

interface SubscriptionFormHeaderProps {
  tenant: Tenant;
  subscription: CustomerProductsSubscription | undefined;
  hidden: boolean;
  setHidden: (value: boolean) => void;
  onSubmit: (values: SubscriptionFormValues) => Promise<void>;
  error?: HttpError | null;
}

export const SubscriptionFormHeader: FunctionComponent<SubscriptionFormHeaderProps> = ({
  tenant,
  subscription,
  hidden,
  setHidden,
  onSubmit,
  error
}) => {
  const { isDirty, isSubmitting } = useFormState<SubscriptionFormValues>();
  const { data: products = [] } = useSalesProducts();

  const subscriptionProducts: SubscriptionItemProducts[] = useWatch({
    name: "subscriptionProducts"
  });

  const { control, getValues } = useFormContext<SubscriptionFormValues>();
  const { append } = useFieldArray({ name: "subscriptionProducts", control });
  const navigate = useNavigate();
  return (
    <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
      <ActionButton
        iconProps={{ iconName: "NavigateBack" }}
        onClick={() => navigate(-1)}
        styles={{
          root: { fontSize: 18, alignSelf: "start" },
          icon: { fontSize: 32 }
        }}
      >
        Back to subscriptions list
      </ActionButton>
      <Stack horizontal tokens={{ childrenGap: 8 }}>
        {!subscription?.cancellationDate && (
          <AddSubscriptionItemDialog
            tenant={tenant}
            selectedProducts={subscriptionProducts}
            hidden={hidden}
            setHidden={setHidden}
            onSubmit={async (values: AddSubscriptionItemDialogValues) => {
              const quantity = values.items.reduce((sum, i) => {
                const int = i.quantity ? Number(i.quantity) : 0;
                return sum + int;
              }, 0);

              const product = products.find(
                p => p.id === values.salesProductId
              );

              const update: SubscriptionItemProducts = {
                salesProductId: values.salesProductId!,
                salesProductFamily: product?.productFamily,
                salesProductName: product?.displayName,
                quantity,
                childTenants: values.items.map(i => ({
                  childTenantId: i.childTenantId,
                  childTenantName: i.childTenantName,
                  salesProductId: values.salesProductId!,
                  quantity: 0,
                  scheduledQuantity: Number(i.quantity ?? 0)
                }))
              };
              append(update);
              setHidden(true);
            }}
          />
        )}
        <ApiCallButton
          error={error}
          isLoading={isSubmitting}
          disabled={
            !isDirty || isSubmitting || !!subscription?.cancelAtPeriodEnd
          }
          primary
          iconProps={{ iconName: "Save" }}
          onClick={() => {
            const values = getValues();
            onSubmit(values);
          }}
        >
          Save
        </ApiCallButton>
      </Stack>
    </Stack>
  );
};
