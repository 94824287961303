import React, { FunctionComponent } from "react";
import {
  ConfirmDialog,
  ConfirmDialogProps,
  Separator,
  Stack
} from "@bps/fluent-ui";
import { PreviewSubscriptionList } from "./PreviewSubscriptionList";
import {
  Country,
  SubscriptionPreviewDto
} from "@libs/api/gateways/plt/plt-gateway.dtos";
import { ProRataCosts } from "./ProRataCosts";
import { PromotionCodes } from "../PromotionCodes";
import { PreviewSubscriptionTotals } from "./PreviewSubscriptionTotals";
import { useTenantQuery } from "@libs/api/gateways/plt/plt-gateway.hooks";
import { useParams } from "react-router-dom";
import { SubscriptionFormValues } from "../subscription-form/SubscriptionForm.types";
import { useFormContext } from "react-hook-form";

interface PreviewSubscriptionDialogProps
  extends Pick<ConfirmDialogProps, "onCancel"> {
  previewData: SubscriptionPreviewDto;
  onConfirm: (values: SubscriptionFormValues) => Promise<void>;
}
export const PreviewSubscriptionDialog: FunctionComponent<PreviewSubscriptionDialogProps> = ({
  previewData,
  onCancel,
  onConfirm
}) => {
  // Parent subscription form context
  const { getValues } = useFormContext<SubscriptionFormValues>();
  const { tenantId } = useParams<{ tenantId: string }>();

  const { data: tenant } = useTenantQuery(tenantId!);
  return (
    <ConfirmDialog
      minWidth={650}
      dialogContentProps={{
        styles: { subText: { marginBottom: 4 } },
        title: "Subscription update review",
        subText: "Would you like to confirm the changes to your subscription?"
      }}
      confirmButtonProps={{
        children: "Confirm"
      }}
      cancelButtonProps={{ children: "Cancel" }}
      hidden={false}
      onConfirm={() => {
        const values = getValues();
        onConfirm(values);
      }}
      onCancel={onCancel}
    >
      <Stack styles={{ root: { height: "50vh" } }}>
        <ProRataCosts
          previewData={previewData}
          country={tenant?.country ?? Country.Australia}
        />
        <Stack.Item styles={{ root: { padding: "8px 0" } }}>
          <PromotionCodes promotionCodeList={previewData.promotionCodeList} />
        </Stack.Item>

        <PreviewSubscriptionTotals
          previewData={previewData}
          country={tenant?.country ?? Country.Australia}
        />
        <Separator />
        <PreviewSubscriptionList previewData={previewData} />
      </Stack>
    </ConfirmDialog>
  );
};
