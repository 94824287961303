import { DateTime } from "@bps/utils";
import { LicenceDto } from "@libs/api/gateways/plt/plt-gateway.dtos";

import { LicenceItem } from "./LicencesList.types";

const INVALID_TEXT = "Invalid";
const VALID_TEXT = "Valid";
export const mapToLicenceItems = (
  items: LicenceDto[] | undefined
): LicenceItem[] => {
  if (!items) return [];
  return items.map(i => {
    const expireDataTime = DateTime.fromISO(i.expiryDate);
    const status =
      i.isInactive || expireDataTime < DateTime.now()
        ? INVALID_TEXT
        : VALID_TEXT;

    return {
      id: i.id,
      username: i.username,
      userId: i.userId,
      licenceTypeCode: i.licenceTypeCode,
      isInactive: i.isInactive,
      doNotRenew: i.doNotRenew,
      expiryDate: expireDataTime,
      productId: i.productId,
      source: i.source,
      updatedDate:
        i.changeLog?.updatedDate || i.changeLog?.createdDate
          ? DateTime.fromISO(
              i.changeLog.updatedDate ?? i.changeLog.createdDate ?? ""
            )
          : undefined,
      status
    };
  });
};
