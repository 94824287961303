import { useMemo, useState } from "react";

import {
  DefaultButton,
  NoDataTile,
  ShimmeredDetailsListProps,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import {
  RolloutPackageDesiredConfig,
  RolloutPackageDesiredConfigCreateRequest,
  RolloutPackageDesiredConfigUpdateRequest,
  SoftwarePackageVersionConfig
} from "@libs/api/gateways/field/field-ops-gateway.dtos";
import {
  useCreateRolloutPackageDesiredConfig,
  useDeleteRolloutPackageDesiredConfig,
  useUpdateRolloutPackageDesiredConfig
} from "@libs/api/gateways/field/field-ops-gateway.hooks";
import { ChangeLogDto } from "@libs/api/types/common-dtos";

import { RolloutPackageFilterValues } from "../RolloutPackageFilter";
import { RolloutPackageDesiredConfigFilter } from "./RolloutPackageDesiredConfigFilter";
import { RolloutPackageDesiredConfigTable } from "./RolloutPackageDesiredConfigTable";

export interface RolloutPackageConfigurationDto {
  id: string;
  rolloutPackageId: string;
  softwarePackageVersionId: string;
  key: string;
  value: string;
  defaultValue: string;
  dataType: string;
  description: string;
  eTag?: string;
  changeLog: ChangeLogDto;
}

interface EditRolloutPackageDesiredConfigProps
  extends ShimmeredDetailsListProps {
  desiredConfigs: RolloutPackageDesiredConfig[];
  rolloutPackageId: string;
  onDismiss: () => void;
  rolloutOccurred?: boolean;
}

export const EditRolloutPackageDesiredConfig = ({
  items,
  desiredConfigs,
  rolloutPackageId,
  onDismiss,
  rolloutOccurred,
  ...props
}: EditRolloutPackageDesiredConfigProps) => {
  const theme = useTheme();

  const [changedConfigs, setChangedConfigs] = useState<
    RolloutPackageDesiredConfig[]
  >([]);

  const combinedDesiredConfigs = useMemo(() => {
    const combinedArray = [...changedConfigs];
    desiredConfigs.forEach(config => {
      if (!combinedArray.find(x => x.key === config.key)) {
        combinedArray.push(config);
      }
    });

    return combinedArray;
  }, [desiredConfigs, changedConfigs]);

  const combinedItems = getCombinedItems(items, combinedDesiredConfigs);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const {
    mutateAsync: updateDesiredConfig
  } = useUpdateRolloutPackageDesiredConfig(rolloutPackageId);

  const {
    mutateAsync: createDesiredConfig
  } = useCreateRolloutPackageDesiredConfig(rolloutPackageId);

  const {
    mutateAsync: deleteDesiredConfig
  } = useDeleteRolloutPackageDesiredConfig(rolloutPackageId);

  const handleUpdateRolloutPackageDesiredConfig = (
    key: string,
    value: string
  ) => {
    // Add or update the changed set
    let updatedConfigs = [...changedConfigs];
    const existingConfig = desiredConfigs.find(x => x.key === key);
    if (existingConfig && existingConfig.value === value) {
      // Updated value is the same as existing desired; remove from changed set
      updatedConfigs = updatedConfigs.filter(x => x.key !== key);
    } else {
      // Look for changed row with this key
      const updatedConfig = updatedConfigs.find(x => x.key === key);
      if (updatedConfig) {
        const defaultConfig = items.find(x => x.key === key);
        if (!existingConfig && defaultConfig.defaultValue === value) {
          // It's not in our existing items and the updated value is the same as the default, remove from changed set
          updatedConfigs = updatedConfigs.filter(x => x.key !== key);
        } else {
          // Update value of already changed item
          updatedConfig.value = value;
        }
      } else {
        // It's not in our changed set, add it
        if (existingConfig) {
          // Map from existing desired config with new value
          updatedConfigs.push({
            ...existingConfig,
            value
          });
        } else {
          // Create new desired config
          updatedConfigs.push({
            rolloutPackageId,
            key,
            value
          });
        }
      }
    }

    setChangedConfigs(updatedConfigs);
  };

  const save = async () => {
    setIsSubmitting(true);

    const updatePromises = Array.from(changedConfigs).map(
      async rolloutPackageDesiredConfig => {
        const { id, key, value, eTag, changeLog } = rolloutPackageDesiredConfig;

        // Get the default config
        const defaultConfig = items.find(x => x.key === key);
        if (defaultConfig.defaultValue === value && id) {
          // Value was changed to the default; remove it
          await deleteDesiredConfig(id);
        } else {
          // Create or update
          const createRequest = {
            rolloutPackageId,
            key,
            value
          } as RolloutPackageDesiredConfigCreateRequest;

          if (id) {
            const updateRequest = {
              ...createRequest,
              id,
              eTag,
              changeLog
            } as RolloutPackageDesiredConfigUpdateRequest;

            await updateDesiredConfig(updateRequest);
          } else {
            await createDesiredConfig(createRequest);
          }
        }
      }
    );

    await Promise.all(updatePromises);

    setIsSubmitting(false);

    close();
  };

  const close = () => {
    // reset the selected rollout device configs
    setChangedConfigs([]);
    onDismiss();
  };

  return (
    <Stack tokens={{ childrenGap: theme.spacing.m }}>
      <RolloutPackageDesiredConfigFilter>
        {({ values }) => {
          const filteredValues = filterAndSortDesiredConfigs(
            combinedItems,
            values
          );

          if (filteredValues.length === 0) {
            return (
              <NoDataTile
                styles={{ root: { height: 500 } }}
                showBoxShadow={false}
                textProps={{ text: "No configuration settings found." }}
                linkProps={{}}
              />
            );
          }

          return (
            <RolloutPackageDesiredConfigTable
              onUpdateRolloutPackageDesiredConfig={
                handleUpdateRolloutPackageDesiredConfig
              }
              items={filteredValues}
              selectionPreservedOnEmptyClick
              setKey="rollout-package-desired-config-table"
              {...props}
              rolloutOccurred={rolloutOccurred}
            />
          );
        }}
      </RolloutPackageDesiredConfigFilter>

      <Stack
        horizontal
        tokens={{ childrenGap: theme.spacing.s1 }}
        horizontalAlign="end"
      >
        <DefaultButton
          disabled={
            isSubmitting || rolloutOccurred || changedConfigs.length === 0
          }
          onClick={save}
        >
          <span>Save</span>
        </DefaultButton>
        <DefaultButton onClick={close}>Cancel</DefaultButton>
      </Stack>
    </Stack>
  );
};

const filterAndSortDesiredConfigs = (
  desiredConfigs: RolloutPackageConfigurationDto[],
  filter: RolloutPackageFilterValues
): RolloutPackageConfigurationDto[] => {
  return desiredConfigs
    .filter(
      d =>
        filter.name === "" ||
        d.key.toLowerCase().indexOf(filter.name.toLowerCase()) >= 0
    )
    .sort(
      (
        a: RolloutPackageConfigurationDto,
        b: RolloutPackageConfigurationDto
      ) => {
        return a.key < b.key ? 1 : -1;
      }
    );
};

// Combine software package version configs and rollout package desired configs into a set of dtos for this rollout package
function getCombinedItems(
  softwarePackageVersionConfigs: SoftwarePackageVersionConfig[],
  rolloutPackageDesiredConfigs: RolloutPackageDesiredConfig[]
): RolloutPackageConfigurationDto[] {
  const configurations = softwarePackageVersionConfigs.map(
    softwarePackageVersionConfig => {
      const desiredConfig = rolloutPackageDesiredConfigs?.find(
        x => x.key === softwarePackageVersionConfig.key
      );

      const {
        key,
        softwarePackageVersionId,
        defaultValue,
        dataType,
        description
      } = softwarePackageVersionConfig;

      return {
        id: desiredConfig?.id,
        rolloutPackageId: desiredConfig?.rolloutPackageId,
        value:
          desiredConfig?.value ?? softwarePackageVersionConfig?.defaultValue,
        key,
        eTag: desiredConfig?.eTag,
        changeLog: desiredConfig?.changeLog,
        softwarePackageVersionId,
        defaultValue,
        dataType,
        description
      } as RolloutPackageConfigurationDto;
    }
  );

  return configurations;
}
