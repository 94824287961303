import { useEffect, useRef } from "react";
import { getHasNewProductFamilies } from "./subscription-form.utils";
import { SubscriptionItemProducts } from "./SubscriptionItems.types";
import { useWatch } from "react-hook-form";

export const useRestCouponDialog = (onRestCouponDialog: () => void) => {
  const productFamilies = useRef<string[]>([]);

  const subscriptionProducts: SubscriptionItemProducts[] = useWatch({
    name: "subscriptionProducts"
  });

  useEffect(() => {
    const newProductFamilies = subscriptionProducts?.map(
      sp => sp.salesProductFamily ?? ""
    );

    const hasNewProductFamilies = getHasNewProductFamilies(
      productFamilies.current,
      newProductFamilies
    );

    if (hasNewProductFamilies) {
      onRestCouponDialog();
      productFamilies.current = newProductFamilies;
    }
  }, [subscriptionProducts, onRestCouponDialog]);
};
