import { Route, Routes } from "react-router-dom";

import { SystemBreadcrumbs } from "../../breadcrumbs/SystemBreadcrumbs";
import { SystemOmniTabs } from "./system-omni-tabs.constants";
import { SystemOmniReports } from "./SystemOmniReports";

export const SystemOmniRoutes = () => {
  return (
    <Routes>
      <Route element={<SystemBreadcrumbs tabs={SystemOmniTabs} />}>
        <Route
          path={SystemOmniTabs.Operations.Reports.id}
          element={<SystemOmniReports />}
        />
      </Route>
    </Routes>
  );
};
