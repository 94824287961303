import React, { useMemo } from "react";
import { matchPath, Outlet, useLocation } from "react-router-dom";

import { IBreadcrumbItem } from "@bps/fluent-ui";
import { Breadcrumbs } from "@components/breadcrumbs/Breadcrumbs";
import { getDefaultBreadcrumbItems } from "@components/breadcrumbs/breadcrumbs.utils";
import { Tabs } from "@components/tabs-nav/TabNav.types";

import { DeploymentRingBreadcrumb } from "./DeploymentRingBreadcrumb";
import { RolloutBreadcrumb } from "./RolloutBreadcrumb";

interface SystemBreadcrumbsProps {
  tabs: Tabs;
}
export const SystemBreadcrumbs: React.FC<SystemBreadcrumbsProps> = ({
  tabs
}) => {
  const { pathname } = useLocation();

  const items = useMemo<IBreadcrumbItem[]>(() => {
    return getSystemItems({ pathname, tabs });
  }, [pathname, tabs]);
  return (
    <>
      <Breadcrumbs items={items} />
      <Outlet />
    </>
  );
};

const getSystemItems = (options: { pathname: string; tabs: Tabs }) => {
  const { pathname, tabs } = options;
  const isDeploymentRingsRoute = pathname.startsWith(
    "/system/plt/deployment-rings"
  );

  if (!isDeploymentRingsRoute) return getDefaultBreadcrumbItems(tabs, pathname);

  const _items: IBreadcrumbItem[] = [
    {
      key: "system",
      text: "System"
    },
    {
      key: "plt",
      text: "Platform"
    },
    {
      key: "deployment-rings",
      text: "Deployment Rings"
    }
  ];

  const baseMatch = matchPath(
    "/system/plt/deployment-rings/:deploymentRingId/*",
    pathname
  );

  const rolloutMatch = matchPath(
    "/system/plt/deployment-rings/:deploymentRingId/rollouts/:rolloutId",
    pathname
  );

  const deploymentRingId =
    baseMatch?.params?.deploymentRingId ??
    rolloutMatch?.params?.deploymentRingId;

  const rolloutId = rolloutMatch?.params.rolloutId;

  if (deploymentRingId) {
    _items.push({
      key: "deploymentRingId",
      text: deploymentRingId,
      onRender: () => (
        <DeploymentRingBreadcrumb deploymentRingId={deploymentRingId} />
      )
    });

    if (rolloutId) {
      _items.push({
        key: "rollouts",
        text: "Rollout"
      });

      _items.push({
        key: rolloutId,
        text: rolloutId,
        onRender: () => (
          <RolloutBreadcrumb
            deploymentRingId={deploymentRingId}
            rolloutId={rolloutId}
          />
        )
      });
    }
  }

  return _items;
};
