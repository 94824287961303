import { PropsWithChildren } from "react";

import {
  IBreadcrumbStyles,
  mergeStyleSets,
  Text,
  useTheme
} from "@bps/fluent-ui";

const breadcrumbStyle: Partial<IBreadcrumbStyles> = {
  root: {
    fontSize: "18px",
    lineHeight: "36px",
    fontWeight: 400,
    padding: "0px 8px"
  }
};

export const CustomBreadcrumb = ({ children }: PropsWithChildren<any>) => {
  const theme = useTheme();
  return (
    <Text
      styles={mergeStyleSets(breadcrumbStyle, {
        root: {
          "&:not(:last-child)": {
            color: theme.palette.neutralSecondary
          },
          "&:last-child": {
            color: theme.palette.neutralPrimary,
            fontWeight: 600
          }
        }
      })}
    >
      {children}
    </Text>
  );
};
