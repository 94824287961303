import React, { Dispatch, FC, SetStateAction, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Checkbox,
  FontIcon,
  IColumn,
  IconButton,
  IContextualMenuItemProps,
  ScrollablePane,
  Stack,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize,
  TooltipHost,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { CopyableGuid } from "@components/CopyableText";
import { ExpandableRow } from "@components/tables/ExpandableRow";
import { ShimmeredDetailsList } from "@components/tables/ShimmeredDetailsList";
import { useExpandedRows } from "@components/tables/useExpandedRows";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { PltUser } from "@libs/api/gateways/plt/plt-gateway.dtos";
import {
  useSearchUsers,
  useUpdateUser
} from "@libs/api/gateways/plt/plt-gateway.hooks";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";

import { PltTenantApplicationBadge } from "../../../../../_shared-blocks/PltTenantApplicationBadge";
import { TenantUsersFilters } from "../TenantUsersScreen";
import { AdditionalUserInformationRow } from "./AdditionalUserInformationRow";
import { InviteFormDialog } from "../../../../../_shared-blocks/InviteFormDialog";

interface PltUsersTableProps {
  tenantId: string;
  filter: TenantUsersFilters;
  selectedUsersIds: Set<string>;
  setSelectedUsersIds: Dispatch<SetStateAction<Set<string>>>;
  showNotificationsDialog: () => void;
}

export const PltUsersTable: FC<PltUsersTableProps> = ({
  tenantId,
  filter,
  selectedUsersIds,
  setSelectedUsersIds,
  showNotificationsDialog
}) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const [inviteUserId, setInviteUserId] = useState<string | undefined>();

  const bpIdUserId = filter.bpIdUserId === "" ? undefined : filter.bpIdUserId;

  const { isLoading, error, data = [] } = useSearchUsers(
    tenantId,
    filter.name,
    bpIdUserId
  );

  const userToInvite = useMemo(() => {
    if (!inviteUserId || !data.length) return undefined;
    return data.find(user => user.id === inviteUserId);
  }, [data, inviteUserId]);

  const {
    isLoading: isUpdatedUserLoading,
    mutateAsync: updateUser
  } = useUpdateUser();

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltCatalogUserWrite
  );

  const { data: hasInvitationPermission } = usePermissionsCheckQuery([
    Permissions.PltInvitationWrite,
    Permissions.PltInvitationRead
  ]);

  const { data: hasNotificationPermission } = usePermissionsCheckQuery(
    Permissions.PltNotificationsWrite
  );

  const { expandedIds, toggleExpandedIds } = useExpandedRows();

  const handleSelectedUsersIds = (id: string) => {
    setSelectedUsersIds(prev =>
      prev.has(id)
        ? new Set([...prev].filter(x => x !== id))
        : new Set(prev.add(id))
    );
  };

  const renderItem = (
    props: IContextualMenuItemProps,
    tooltipContent: string | undefined
  ) => {
    if (!props.item.disabled)
      return (
        <Text styles={{ root: { padding: "0px 4px" } }}>{props.item.text}</Text>
      );

    return (
      <TooltipHost
        content={tooltipContent}
        styles={{ root: { padding: "0px 4px" } }}
      >
        <Stack
          horizontal
          verticalAlign="center"
          horizontalAlign="center"
          tokens={{ childrenGap: 8 }}
        >
          <Text
            styles={{
              root: {
                color: props.item.disabled
                  ? theme.semanticColors.disabledText
                  : "initial"
              }
            }}
          >
            {props.item.text}
          </Text>
          <FontIcon
            iconName="Info"
            styles={{
              root: { color: theme.semanticColors.severeWarningIcon }
            }}
          />
        </Stack>
      </TooltipHost>
    );
  };

  const columns: IColumn[] = [
    {
      key: "actions",
      name: "",
      maxWidth: 32,
      minWidth: 32,
      onRender: (item: PltUser) => {
        const tenantMismatch = item?.tenantId !== tenantId;

        const disableInvite =
          !hasInvitationPermission || tenantMismatch || !!item?.bpIdUserId;

        const getInviteDisableTooltipContent = () => {
          if (!hasInvitationPermission) return NO_ACTION_PERMISSION;
          if (tenantMismatch)
            return "CAM User cannot be invited to Child Tenant";
          if (!!item?.bpIdUserId) return "User has BpID account already.";
          return undefined;
        };

        return (
          <IconButton
            styles={{ root: { justifySelf: "end" } }}
            menuIconProps={{ iconName: "more" }}
            menuProps={{
              items: [
                {
                  key: "notification",
                  text: "Send notification",
                  onClick: () => {
                    handleSelectedUsersIds(item.id);
                    showNotificationsDialog();
                  },
                  disabled: !hasNotificationPermission,
                  onRenderContent: props =>
                    renderItem(props, NO_ACTION_PERMISSION),
                  itemProps: { styles: { root: { pointerEvents: "auto" } } }
                },
                {
                  key: "edit",
                  text: hasWritePermission ? "Edit/more" : "More",
                  onClick: () => navigate(`./${item.id}`)
                },
                {
                  key: "statusUpdate",
                  text: !item.isInactive ? "Disable" : "Enable",
                  onClick: () => {
                    updateUser({
                      ...item,
                      isInactive: !item.isInactive
                    });
                  },
                  disabled: isUpdatedUserLoading || !hasWritePermission,
                  onRenderContent: props =>
                    renderItem(props, NO_ACTION_PERMISSION),
                  itemProps: { styles: { root: { pointerEvents: "auto" } } }
                },
                {
                  key: "invite",
                  text: "Invite",
                  onClick: () => {
                    setInviteUserId(item.id);
                  },
                  disabled: isUpdatedUserLoading || disableInvite,
                  onRenderContent: props =>
                    renderItem(props, getInviteDisableTooltipContent()),
                  itemProps: { styles: { root: { pointerEvents: "auto" } } }
                }
              ]
            }}
          />
        );
      }
    },
    {
      key: "check",
      name: "",
      maxWidth: 35,
      minWidth: 35,
      onRenderHeader: () => (
        <Checkbox
          automationAttribute="check-for-notifications-all"
          checked={data.length > 0 && data.length === selectedUsersIds.size}
          onChange={(ev, checked) => {
            ev?.stopPropagation();
            if (!checked) {
              setSelectedUsersIds(new Set());
            } else {
              setSelectedUsersIds(new Set(data.map(d => d.id)));
            }
          }}
          styles={{ root: { paddingTop: 8 } }}
        />
      ),
      onRender: (item: PltUser) => (
        <Checkbox
          automationAttribute={`check-for-notification-${item.id}`}
          checked={selectedUsersIds.has(item.id)}
          onChange={ev => {
            ev?.stopPropagation();
            handleSelectedUsersIds(item.id);
          }}
        />
      )
    },
    {
      key: "id",
      name: "Id",
      maxWidth: 100,
      minWidth: 100,
      onRender: (item: PltUser) => <CopyableGuid short value={item.id} />
    },
    {
      key: "firstName",
      name: "First Name",
      maxWidth: 100,
      minWidth: 100,
      onRender: (item: PltUser) => item.firstName
    },
    {
      key: "lastName",
      name: "Last Name",
      maxWidth: 150,
      minWidth: 200,
      onRender: (item: PltUser) => item.lastName
    },
    {
      key: "email",
      name: "Email",
      maxWidth: 250,
      minWidth: 200,
      onRender: (item: PltUser) => item.username
    },
    {
      key: "application",
      name: "Application",
      maxWidth: 170,
      minWidth: 170,
      onRender: (item: PltUser) => (
        <PltTenantApplicationBadge tenantId={item.tenantId} />
      )
    },
    {
      key: "bpIdExists",
      name: "Has Bp ID",
      maxWidth: 75,
      minWidth: 75,
      onRender: (item: PltUser) => (
        <TextBadge
          badgeSize={TextBadgeSize.small}
          badgeColor={
            item.bpIdUserId ? TextBadgeColor.green : TextBadgeColor.red
          }
          styles={{ root: { width: 75 } }}
        >
          {item.bpIdUserId ? "Yes" : "No"}
        </TextBadge>
      )
    },
    {
      key: "externalIdExists",
      name: "Has Ext. ID",
      maxWidth: 75,
      minWidth: 75,
      onRender: (item: PltUser) => (
        <TextBadge
          badgeSize={TextBadgeSize.small}
          badgeColor={
            item.externalId ? TextBadgeColor.green : TextBadgeColor.red
          }
          styles={{ root: { width: 75 } }}
        >
          {item.externalId ? "Yes" : "No"}
        </TextBadge>
      )
    },
    {
      key: "status",
      name: "Status",
      minWidth: 75,
      maxWidth: 75,
      onRender: (item: PltUser) => (
        <TextBadge
          badgeSize={TextBadgeSize.small}
          badgeColor={
            !item.isInactive ? TextBadgeColor.green : TextBadgeColor.red
          }
          styles={{ root: { width: 75 } }}
        >
          {!item.isInactive ? "Active" : "Inactive"}
        </TextBadge>
      )
    },
    {
      key: "statusSite",
      name: "Status (Prem Site)",
      minWidth: 80,
      maxWidth: 100,
      onRender: (item: PltUser) => (
        <TextBadge
          badgeSize={TextBadgeSize.small}
          badgeColor={
            item.status === "ACTIVE" ? TextBadgeColor.green : TextBadgeColor.red
          }
          styles={{ root: { width: 75 } }}
        >
          {item.status === "ACTIVE" ? "Active" : "Inactive"}
        </TextBadge>
      )
    }
  ];

  return (
    <Stack styles={{ root: { position: "relative", width: "100%" } }} grow>
      <ScrollablePane>
        <ShimmeredDetailsList
          items={data}
          columns={columns}
          setKey="plt_users_id"
          errorMessage={error?.message}
          onShouldVirtualize={() => false}
          enableShimmer={isLoading}
          detailsListStyles={{
            contentWrapper: {}
          }}
          onRenderRow={(props, renderDefault) => {
            if (props && renderDefault) {
              return (
                <ExpandableRow
                  id={props.item.id}
                  expandedIds={expandedIds}
                  toggleExpandedIds={toggleExpandedIds}
                  ExpandableComponent={
                    <AdditionalUserInformationRow user={props.item} />
                  }
                  rowProps={props}
                />
              );
            }
            return null;
          }}
        />
        {userToInvite && (
          <InviteFormDialog
            tenantId={userToInvite.tenantId}
            userId={userToInvite.id}
            bpIdUserId={userToInvite.bpIdUserId}
            firstName={userToInvite.firstName}
            lastName={userToInvite.lastName}
            onDismiss={() => setInviteUserId(undefined)}
          />
        )}
      </ScrollablePane>
    </Stack>
  );
};
