import React, { FunctionComponent, useRef, useState } from "react";
import { useNavigate } from "react-router";

import { Form } from "@components/form/Form";
import {
  useAddCustomerSubscription,
  useCustomerSubscriptionPreview
} from "@libs/api/gateways/plt/plt-gateway.hooks";

import { SubscriptionEditFormFields } from "./SubscriptionEditFormFields";
import {
  getHasNewProductFamilies,
  getSubscriptionsProducts
} from "./subscription-form.utils";
import { PreviewSubscriptionDialog } from "../subscription-preview/PreviewSubscriptionDialog";
import { useParams } from "react-router-dom";
import { getPreviewSubscriptionPayload } from "../subscription-preview/preview-subscription.utils";
import {
  SubscriptionFormProps,
  SubscriptionFormValues
} from "./SubscriptionForm.types";
import { CenteredLargeSpinner, FontWeights } from "@bps/fluent-ui";
import { AddCouponFormDialog } from "../add-coupon-dialog/AddCouponFormDialog";

export const SubscriptionForm: FunctionComponent<SubscriptionFormProps> = ({
  customerProducts,
  tenant,
  childTenants
}) => {
  const { id } = useParams<{ id: string }>();
  const [showCouponDialog, setShowCouponDialog] = useState<boolean>(false);
  const isPreviewed = useRef<boolean>(false);
  const isCouponConfirmed = useRef<boolean>(false);

  const {
    mutateAsync: submitSubscription,
    error: submitError,
    isLoading: isSubmitting
  } = useAddCustomerSubscription(tenant.id);

  const {
    mutateAsync: getSubscriptionPreview,
    error: previewError,
    data: previewData,
    isLoading: previewIsLoading,
    reset: resetPreviewData
  } = useCustomerSubscriptionPreview();

  const navigate = useNavigate();
  const subscription = customerProducts?.subscriptionList.find(
    s => s.subscriptionId === id
  );

  const excludePromotionCodeList = subscription?.promotionCodeList ?? [];

  const subscriptionProducts = subscription
    ? getSubscriptionsProducts(subscription, childTenants).filter(
        s => !!s.quantity
      )
    : [];

  const isNewSubscription = !subscription;
  const currentSalesProductFamilies = subscriptionProducts.map(
    sp => sp.salesProductFamily ?? ""
  );

  const handleSubmit = async (values: SubscriptionFormValues) => {
    const payload = getPreviewSubscriptionPayload(values, tenant.id);

    resetPreviewData();
    await submitSubscription(payload);
    navigate(`../../../${tenant.id}/sales-subscriptions`);
  };

  const onTrySubmit = async (values: SubscriptionFormValues) => {
    showCouponDialog && setShowCouponDialog(false);
    const isCouponAdded = !!values.additionalPromotionCodeList?.length;
    const newSalesProductFamilies = values.subscriptionProducts.map(
      sp => sp.salesProductFamily ?? ""
    );

    const hasNewProductFamily = getHasNewProductFamilies(
      currentSalesProductFamilies,
      newSalesProductFamilies
    );

    const isNewSubWithNoCoupons = isNewSubscription && !isCouponAdded;
    const isEditSubWithNewProductFamily =
      !isNewSubscription && hasNewProductFamily && !isCouponAdded;

    if (
      !isCouponConfirmed.current &&
      (isNewSubWithNoCoupons || isEditSubWithNewProductFamily)
    ) {
      return setShowCouponDialog(true);
    }

    const payload = getPreviewSubscriptionPayload(values, tenant.id);
    if (!isPreviewed.current) {
      isPreviewed.current = true;
      await getSubscriptionPreview(payload);
    } else {
      await handleSubmit(values);
    }
  };

  return (
    <Form<SubscriptionFormValues>
      defaultValues={{
        subscriptionProducts,
        promotionCodeList: subscription?.promotionCodeList
      }}
      onSubmit={async () => {}}
      isSubmitting={previewIsLoading || isSubmitting}
      formStyles={{ root: { height: "100%", flexGrow: 1 } }}
      buttonsRootStyles={{ root: { marginTop: "auto" } }}
      hideButtons
      overlayProps={{
        children: (
          <CenteredLargeSpinner
            label={previewIsLoading ? "Loading preview ..." : "Submitting ..."}
            labelPosition="bottom"
            styles={{ label: { fontWeight: FontWeights.semibold } }}
          />
        )
      }}
    >
      <>
        <SubscriptionEditFormFields
          subscription={subscription}
          tenant={tenant}
          onRestCouponDialog={() => {
            isCouponConfirmed.current = false;
          }}
          onSubmit={onTrySubmit}
          error={submitError ?? previewError}
        />
        {previewData && (
          <PreviewSubscriptionDialog
            previewData={previewData}
            onConfirm={handleSubmit}
            onCancel={() => {
              isPreviewed.current = false;
              resetPreviewData();
            }}
          />
        )}

        {/* Only SHOW the modal if the modal has not been displayed before */}
        {!isCouponConfirmed.current && showCouponDialog && (
          <AddCouponFormDialog
            excludePromotionCodeList={excludePromotionCodeList}
            onConfirm={values => {
              isCouponConfirmed.current = true;
              onTrySubmit(values);
            }}
            onDismiss={() => setShowCouponDialog(false)}
          />
        )}
      </>
    </Form>
  );
};
