import { Validator } from "@components/form/validation/Validator";
import { AddCouponFormValues } from "./AddCouponFormDialog.types";

const validator = new Validator<AddCouponFormValues>();
export const validatedCouponForm = (values: AddCouponFormValues) => {
  return validator.validateWithParse(values, {
    isNeedToApply: validator.boolean().required(),
    coupons: validator.custom().predicate({
      when: () => values.isNeedToApply === true,
      then: validator.array().required()
    })
  });
};
