import { FunctionComponent } from "react";
import { PltUser } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { ApiCallButton } from "@components/buttons/ApiCallButton";
import { useClearBpId } from "@libs/api/gateways/bp-id/bp-id.hooks";

interface ClearBpIdButtonProps {
  user: PltUser;
}

export const ClearBpIdButton: FunctionComponent<ClearBpIdButtonProps> = ({
  user
}) => {
  const { mutateAsync: clearBpId, isLoading, error } = useClearBpId(
    user.tenantId,
    user.id
  );

  return (
    <ApiCallButton
      isLoading={isLoading}
      error={error}
      onClick={() => {
        clearBpId();
      }}
      disabled={!user?.bpIdUserId}
    >
      Clear Bp Id
    </ApiCallButton>
  );
};
