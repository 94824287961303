import React, { Fragment, FunctionComponent } from "react";
import {
  FieldItemError,
  Heading,
  HideStack,
  NoDataTile,
  Separator,
  Stack,
  Text
} from "@bps/fluent-ui";
import { FormSpinNumberInput } from "@components/form/fields/FormSpinNumberInput";
import { useFormState, useWatch } from "react-hook-form";
import { Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";

interface ChildTenantsListFieldProps {
  childTenants: Tenant[];
  tenant: Tenant;
}

export const ChildTenantsListField: FunctionComponent<ChildTenantsListFieldProps> = ({
  childTenants,
  tenant
}) => {
  const productId = useWatch({ name: "salesProductId" });
  const { errors } = useFormState();

  if (!productId) {
    return (
      <NoDataTile
        textProps={{ text: "No product selected!" }}
        linkProps={{ hidden: true }}
        showBoxShadow={false}
      />
    );
  }

  return (
    <HideStack when={!productId}>
      <FieldItemError
        name="items"
        errorMessage={errors?.items?.message as string}
        styles={{ root: { paddingBottom: 16 } }}
      />
      {childTenants.map((c, idx) => (
        <Fragment key={c.id}>
          <Stack horizontal horizontalAlign="space-between">
            <Stack>
              <Heading>{c.name}</Heading>
              <Text variant="small">{c.id}</Text>
              <Text variant="small">{c.application}</Text>
              <Text variant="small">{tenant.crmId}</Text>
            </Stack>
            <FormSpinNumberInput
              name={`items.${idx}.quantity`}
              styles={{ root: { width: 50 } }}
            />
          </Stack>
          <Separator />
        </Fragment>
      ))}
    </HideStack>
  );
};
