import { FunctionComponent, useEffect } from "react";
import { PrimaryButton, Stack } from "@bps/fluent-ui";
import { FormButtonsGroupSingleChoice } from "@components/form/fields/FormButtonsGroupSingleChoice";
import { FormTenantPromotionCodeSelect } from "@components/form/fields/FormTenantPromotionCodeSelect";
import { useParams } from "react-router-dom";
import { useFormContext, useWatch } from "react-hook-form";
import { AddCouponFormDialogProps } from "./AddCouponFormDialog.types";

const options = [
  { key: true, text: "Yes" },
  { key: false, text: "No" }
];

interface AddCouponFormFieldsProps
  extends Omit<AddCouponFormDialogProps, "onDismiss" | "onConfirm"> {
  onConfirm: (coupons: string[]) => void;
}

export const AddCouponFormFields: FunctionComponent<AddCouponFormFieldsProps> = ({
  excludePromotionCodeList,
  onConfirm
}) => {
  const { tenantId } = useParams<{ tenantId: string }>();
  const isNeedToApply = useWatch({ name: "isNeedToApply" });
  const coupons = useWatch({ name: "coupons" });
  const {
    trigger,
    clearErrors,
    formState: { isDirty }
  } = useFormContext();

  useEffect(() => {
    if (typeof isNeedToApply !== "undefined") {
      if (isNeedToApply === false) {
        clearErrors();
      }
    }
  }, [clearErrors, isNeedToApply]);

  useEffect(() => {
    if (!!isNeedToApply && coupons?.lenght) {
      // ????
    }
  }, [coupons, isNeedToApply]);

  return (
    <Stack tokens={{ childrenGap: 16 }}>
      <FormButtonsGroupSingleChoice
        name="isNeedToApply"
        label="Does a discount need to be applied to this purchase?"
        required
        notUnselectable
        options={options}
      />
      <FormTenantPromotionCodeSelect
        label="Promotion codes"
        disabled={!isNeedToApply}
        required={!!isNeedToApply}
        name="coupons"
        tenantId={tenantId!}
        excludes={excludePromotionCodeList}
      />
      <PrimaryButton
        styles={{ root: { width: "fit-content", alignSelf: "end" } }}
        disabled={!isDirty}
        onClick={async () => {
          const isValid = await trigger();
          if (isValid) {
            onConfirm(coupons);
          }
        }}
      >
        Save
      </PrimaryButton>
    </Stack>
  );
};
