import React, { FunctionComponent } from "react";
import { Stack } from "@bps/fluent-ui";
import { FormCheckbox } from "@components/form/fields/FormCheckbox";
import { FormPinField } from "@components/form/fields/FormPinField";
import { useWatch } from "react-hook-form";

export const RedirectLinkField: FunctionComponent = () => {
  const usePin = useWatch({ name: "usePin" });
  return (
    <Stack>
      <FormCheckbox name="usePin" label="Require PIN code" />
      {usePin && (
        <FormPinField
          name="pin"
          description="Leave blank to get an automatically generated code."
        />
      )}
    </Stack>
  );
};
