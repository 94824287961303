import { Route, Routes } from "react-router-dom";

import { SystemBreadcrumbs } from "../../breadcrumbs/SystemBreadcrumbs";
import { SystemBpMobileTabs } from "./system-bp-mobile-tabs.constants";
import { BpMobileApp } from "./SystemBpMobileApp";
import { BpMobileNotifications } from "./SystemBpMobileNotifications";

export const SystemBpMobileRoutes = () => {
  return (
    <Routes>
      <Route element={<SystemBreadcrumbs tabs={SystemBpMobileTabs} />}>
        <Route
          path={SystemBpMobileTabs.Operations.Settings.id}
          element={<BpMobileApp />}
        />
        <Route
          path={SystemBpMobileTabs.Operations.Notifications.id}
          element={<BpMobileNotifications />}
        />
      </Route>
    </Routes>
  );
};
