import React, { FunctionComponent, useEffect, useMemo } from "react";
import { useQueryClient } from "react-query";
import { useFormContext, useWatch } from "react-hook-form";
import {
  Dialog,
  IDialogProps,
  IDropdownOption,
  Option,
  Stack,
  Text
} from "@bps/fluent-ui";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { Form } from "@components/form/Form";
import { FormCheckbox } from "@components/form/fields/FormCheckbox";
import { FormDropdown } from "@components/form/fields/FormDropdown";
import { FormPinField } from "@components/form/fields/FormPinField";
import { FormTextField } from "@components/form/fields/FormTextField";
import {
  IssuerType,
  SignInTypeKeys
} from "@libs/api/gateways/bp-id/bp-id.dtos";
import {
  ApplicationType,
  getApplicationNameFromAppCode
} from "@libs/api/gateways/plt/plt-gateway.dtos";
import {
  useCloudApplicationsRefData,
  useSendUserInvite
} from "@libs/api/gateways/bp-id/bp-id.hooks";
import {
  CamCacheKeys,
  useCreateCustomerAccountUser
} from "@libs/api/gateways/cam/cam-gateway.hooks";
import { useTenantQuery } from "@libs/api/gateways/plt/plt-gateway.hooks";
import { PltTenantApplicationBadge } from "./PltTenantApplicationBadge";
import { Validator } from "@components/form/validation/Validator";
import { ValidationSchema } from "@bps/utils";
import { pinLengthSchema } from "./FormPin.validator";
import { InviteFormUserCheck } from "./InviteFormUserCheck";
import { pinConfirmation } from "./pin-confirmation";

interface InviteFormDialogProps extends Pick<IDialogProps, "onDismiss"> {
  tenantId: string;
  userId?: string;
  bpIdUserId?: string;
  crmId?: string;
  crmContactId?: string;
  firstName?: string;
  lastName?: string;
}

export interface InviteFormValues {
  email: string;
  signInType: SignInTypeKeys;
  shortLinkPin?: string;
  useShortLinkPin?: boolean;
  redirectUrl?: string;
}

const defaultRedirectOptions: IDropdownOption[] = [
  {
    key:
      "https://kb.bpsoftware.net/bestpracticemobile/onboarding/Download_BpMobile.htm",
    text: "Download Best Practice Mobile"
  }
];

const PIN_CONFORMATION_PIN_SUB_TEXT =
  "A pin code is required to redeem this invitation. Record this pin code as it will not be shown again.";

const validator = new Validator<InviteFormValues>();
const schema: ValidationSchema<InviteFormValues> = {
  email: [validator.string().required(), validator.string().email()],
  shortLinkPin: validator.custom().predicate<"shortLinkPin">({
    when: (_, parentValues) => !!parentValues.useShortLinkPin,
    then: pinLengthSchema
  })
};

export const InviteFormDialog: FunctionComponent<InviteFormDialogProps> = ({
  tenantId,
  userId,
  bpIdUserId,
  crmId,
  crmContactId,
  firstName,
  lastName,
  onDismiss
}) => {
  const queryClient = useQueryClient();

  const {
    data: cloudApplications = [],
    isLoading: isLoadingCloudApplications,
    error: cloudApplicationsError
  } = useCloudApplicationsRefData();

  const cloudApplicationsOptions = useMemo<IDropdownOption[]>(() => {
    return cloudApplications
      .filter(app => !!app.primaryUrl)
      .map(app => ({ key: app.primaryUrl!, text: app.text }));
  }, [cloudApplications]);

  const signInTypeOptions: Option[] = [
    { key: SignInTypeKeys.email, text: "Email address" },
    { key: SignInTypeKeys.federated, text: "Federated" }
  ];

  const { data: tenant } = useTenantQuery(tenantId);

  const sendUserInviteResult = useSendUserInvite();

  const {
    error: createUserError,
    mutateAsync: createUser
  } = useCreateCustomerAccountUser();

  const handleUserCreation = async () => {
    if (!userId) {
      return await createUser({
        tenantId,
        firstName,
        lastName,
        crmContactId,
        bpIdUserId
      });
    }
    return undefined;
  };

  const onSubmit = async (values: InviteFormValues) => {
    const missingTenantUser = await handleUserCreation();
    const tenantUserId = userId ?? missingTenantUser?.tenantUserId;
    const applicationName = getApplicationNameFromAppCode(tenant?.application);

    const userInviteResponse = await sendUserInviteResult.mutateAsync({
      signInEmail: values.email,
      signInType: values.signInType,
      generateShortLinkId: true,
      firstName,
      lastName,
      tenantId,
      userId: tenantUserId,
      signInBpIdUserId: bpIdUserId,
      issuer:
        values.signInType !== SignInTypeKeys.email
          ? IssuerType.bpCloudRemoteAuth
          : undefined,
      issuerAssignedId:
        values.signInType !== SignInTypeKeys.email
          ? `${tenant?.application}\\${applicationName}\\${tenantId}\\${tenantUserId}`
          : undefined,
      useShortLinkPin: values.useShortLinkPin,
      shortLinkPin: values.useShortLinkPin ? values.shortLinkPin : undefined,
      redirectUrl: values.redirectUrl
    });

    // if a customer has been created invalidate customer account queries
    if (crmId && missingTenantUser) {
      await queryClient.invalidateQueries([
        CamCacheKeys.CustomerAccountUsers,
        crmContactId
      ]);
      await queryClient.invalidateQueries([
        CamCacheKeys.CustomerAccountAllUsers,
        crmId
      ]);
    }

    if (userInviteResponse.pin) {
      const isConfirmed = await pinConfirmation(
        userInviteResponse.pin,
        PIN_CONFORMATION_PIN_SUB_TEXT
      );
      if (isConfirmed) {
        onDismiss!();
      }
    } else {
      onDismiss!();
    }
  };

  return (
    <Dialog
      hidden={false}
      onDismiss={onDismiss}
      dialogContentProps={{
        showCloseButton: true,
        title: "Send Invite",
        styles: { title: { paddingBottom: 20 } }
      }}
      styles={{ main: { minHeight: 450 } }}
      minWidth={350}
    >
      <QueryStateIndicator
        isLoading={isLoadingCloudApplications}
        error={cloudApplicationsError}
        allowNullOrUndefined
      >
        {() => (
          <Form<InviteFormValues>
            validate={values => validator.validateWithParse(values, schema)}
            defaultValues={{ email: "", signInType: SignInTypeKeys.email }}
            submitButtonText="Send"
            onSubmit={onSubmit}
            error={sendUserInviteResult.error ?? createUserError}
            cancelButtonText="Close"
            showCancelButton
            onCancel={() => onDismiss?.()}
          >
            {tenant?.application && (
              <Stack horizontal tokens={{ childrenGap: 12 }}>
                <Text>Application:</Text>
                <PltTenantApplicationBadge
                  applicationCode={tenant?.application}
                />
              </Stack>
            )}

            <Stack tokens={{ childrenGap: 16 }} verticalAlign="end">
              <FormTextField label="Email" name="email" />

              <FormEmailFieldWatcher tenantId={tenantId} />

              <FormDropdown
                name="signInType"
                options={signInTypeOptions.filter(option => {
                  // User Invitations should only ALLOW Federated Invitations for PR TenantUsers
                  if (tenant?.application !== ApplicationType.PROS) {
                    return option.key === SignInTypeKeys.email;
                  }
                  return true;
                })}
                label="SignIn Type"
                disabled={tenant?.application !== ApplicationType.PROS}
                fieldItemStyles={{ root: { flexGrow: 1, flexBasis: 0 } }}
              />
              <FormDropdown
                name="redirectUrl"
                options={[
                  ...cloudApplicationsOptions,
                  ...defaultRedirectOptions
                ]}
                label="Redirect Url"
                fieldItemStyles={{ root: { flexGrow: 1, flexBasis: 0 } }}
                hint="Optional"
                withNoEmptyOption={false}
              />

              <Stack
                tokens={{ childrenGap: 8 }}
                styles={{ root: { height: "120px" } }}
              >
                <FormCheckbox name="useShortLinkPin" label="Require Pin Code" />
                <FormPinFieldWatcher />
              </Stack>
            </Stack>
          </Form>
        )}
      </QueryStateIndicator>
    </Dialog>
  );
};

const FormPinFieldWatcher: FunctionComponent = () => {
  const useShortLinkPin = useWatch({ name: "useShortLinkPin" });
  const shortLinkPin = useWatch({ name: "shortLinkPin" });
  const { setValue } = useFormContext();
  useEffect(() => {
    if (!useShortLinkPin && shortLinkPin) {
      setValue("shortLinkPin", "");
    }
  }, [setValue, shortLinkPin, useShortLinkPin]);

  if (!useShortLinkPin) return null;

  return (
    <FormPinField
      name="shortLinkPin"
      description="Leave blank to get an automatically generated code."
    />
  );
};

export interface FormEmailFieldWatcherProps {
  tenantId: string;
}

const FormEmailFieldWatcher: FunctionComponent<FormEmailFieldWatcherProps> = ({
  tenantId
}) => {
  const email = useWatch({ name: "email" });

  return email && <InviteFormUserCheck email={email} tenantId={tenantId} />;
};
