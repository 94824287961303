import { FunctionComponent } from "react";

import { DefaultButton, Dialog } from "@bps/fluent-ui";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { useRedirectLink } from "@libs/api/gateways/plt-redirect/plt-redirect-gateway.hooks";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";

import {
  ADD_LINK_TITLE,
  AddRedirectLinkDialogProps,
  EDIT_LINK_TITLE
} from "./add-redirect-link-dialog.types";
import { AddRedirectLinkForm } from "./AddRedirectLinkForm";

export const AddRedirectLinkDialog: FunctionComponent<AddRedirectLinkDialogProps> = ({
  linkId,
  setLinkId
}) => {
  const redirectLinkQuery = useRedirectLink(linkId!, {
    enabled: !!linkId
  });

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltCatalogOpsWrite
  );

  const title = !!linkId ? EDIT_LINK_TITLE : ADD_LINK_TITLE;
  const onDismiss = () => setLinkId(undefined);
  return (
    <>
      {typeof linkId !== "undefined" && (
        <Dialog
          hidden={false}
          dialogContentProps={{
            title,
            showCloseButton: true,
            onDismiss
          }}
          minWidth={400}
        >
          <QueryStateIndicator {...redirectLinkQuery} allowNullOrUndefined>
            {redirectLink => (
              <AddRedirectLinkForm
                onDismiss={onDismiss}
                redirectLink={redirectLink}
              />
            )}
          </QueryStateIndicator>
        </Dialog>
      )}
      <DefaultButton
        disabled={!hasWritePermission}
        title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
        onClick={() => setLinkId(null)}
      >
        {ADD_LINK_TITLE}
      </DefaultButton>
    </>
  );
};
