import React from "react";

import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { useRollouts } from "@libs/api/gateways/field/field-ops-gateway.hooks";

import { CustomBreadcrumb } from "./CustomBreadcrumb";

interface RolloutBreadcrumbProps {
  deploymentRingId: string;
  rolloutId: string;
}

export const RolloutBreadcrumb: React.FC<RolloutBreadcrumbProps> = ({
  deploymentRingId,
  rolloutId
}) => {
  const rolloutsQuery = useRollouts(deploymentRingId);

  return (
    <QueryStateIndicator {...rolloutsQuery}>
      {rollouts => {
        const rollout = rollouts.find(x => x.id === rolloutId);

        return (
          <CustomBreadcrumb>
            {rollout?.displayName ?? rollout?.id ?? "..."}
          </CustomBreadcrumb>
        );
      }}
    </QueryStateIndicator>
  );
};
