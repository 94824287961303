import { FeatureExplorer } from "modules/system/sections/plt/feature/FeatureExplorer";
import { FeatureGraph } from "modules/system/sections/plt/feature/FeatureGraph";
import { SystemRoles } from "modules/system/sections/plt/roles/SystemRoles";
import { Route, Routes } from "react-router-dom";

import { SystemBreadcrumbs } from "../../breadcrumbs/SystemBreadcrumbs";
import { AuditLogs } from "./bpid/AuditLogs";
import { Invitations } from "./bpid/Invitations";
import { EmailsAuditingRouter } from "./comms";
import { CommsTesting } from "./comms/CommsTesting";
import { ComponentsDefinitionsScreen } from "./components-definitions";
import { FieldConfigScreen } from "./field-management/defaultFieldConfig";
import { DeploymentRingsRouter } from "./field-management/rings";
import { SelectorsScreen } from "./field-management/selectors";
import { CallbackTypesScreen } from "./integrators/callback-types";
import { LicenceCodes } from "./licence-types";
import { ProductsScreen } from "./products";
import { RedirectsScreen } from "./redirects";
import { SystemPltTabs } from "./system-plt-tabs.constants";

export const SystemPltRoutes = () => {
  return (
    <Routes>
      <Route element={<SystemBreadcrumbs tabs={SystemPltTabs} />}>
        <Route
          path={SystemPltTabs.Catalog.FeatureGraph.id}
          element={<FeatureGraph onlyFeatures={["TI"]} />}
        />
        <Route
          path={SystemPltTabs.Catalog.FeatureExplorer.id}
          element={<FeatureExplorer />}
        />
        <Route
          path={SystemPltTabs.Catalog.SystemRoles.id}
          element={<SystemRoles />}
        />
        <Route
          path={SystemPltTabs.Catalog.Components.id}
          element={<ComponentsDefinitionsScreen />}
        />
        <Route
          path={`${SystemPltTabs.FieldMgmt.DeploymentRings.id}/*`}
          element={<DeploymentRingsRouter />}
        />
        <Route
          path={`${SystemPltTabs.Comms.Emails.id}/*`}
          element={<EmailsAuditingRouter />}
        />
        <Route
          path={`${SystemPltTabs.Comms.CommsTesting.id}/*`}
          element={<CommsTesting />}
        />
        <Route
          path={SystemPltTabs.FieldMgmt.FieldConfig.id}
          element={<FieldConfigScreen />}
        />
        <Route
          path={SystemPltTabs.Sales.Product.id}
          element={<ProductsScreen />}
        />
        <Route
          path={SystemPltTabs.Sales.LicencesCodes.id}
          element={<LicenceCodes />}
        />
        <Route
          path={SystemPltTabs.Integrators.Callbacks.id}
          element={<CallbackTypesScreen />}
        />
        <Route
          path={SystemPltTabs.Redirects.RedirectLinks.id}
          element={<RedirectsScreen />}
        />
        <Route
          path={SystemPltTabs.BpId.Invitations.id}
          element={<Invitations />}
        />
        <Route path={SystemPltTabs.BpId.AuditLogs.id} element={<AuditLogs />} />
        <Route
          path={SystemPltTabs.FieldMgmt.Selectors.id}
          element={<SelectorsScreen />}
        />
      </Route>
    </Routes>
  );
};
