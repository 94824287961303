import { useMemo } from "react";

import {
  DetailsRow,
  IColumn,
  ScrollablePane,
  ShimmeredDetailsListProps,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { ShimmeredDetailsList } from "@components/tables/ShimmeredDetailsList";

import { RolloutPackageSoftwarePackageSummaryDto } from "./";
import { RolloutPackageSelectionDto } from "./NewRolloutPackage";
import { PackageVersionTableDropDown } from "./PackageVersionTableDropDown";

export interface SoftwarePackageVersionTableProps
  extends ShimmeredDetailsListProps {
  selections: RolloutPackageSelectionDto[];
  onUpdateSoftwarePackageVersion: (
    id: string,
    softwarePackageVersionId: string
  ) => void;
}

export const SoftwarePackageVersionTable = ({
  selections,
  onUpdateSoftwarePackageVersion,
  ...props
}: SoftwarePackageVersionTableProps) => {
  const theme = useTheme();
  const columns: IColumn[] = useMemo(() => {
    return [
      {
        key: "name",
        name: "Name",
        minWidth: 150,
        maxWidth: 800,
        isResizable: true,
        onRender: (dto: RolloutPackageSoftwarePackageSummaryDto) => (
          <Stack
            styles={{
              root: { whiteSpace: "initial" }
            }}
          >
            <Text>{dto.displayName}</Text>
            <Text variant="small">Description: {dto.description}</Text>
            <Text variant="small">Code: {dto.code}</Text>
          </Stack>
        )
      },
      {
        key: "version",
        name: "Version",
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        onRender: (dto: RolloutPackageSoftwarePackageSummaryDto) => {
          const selection = selections.find(
            x => x.softwarePackageId === dto.softwarePackageId
          );

          return (
            <PackageVersionTableDropDown
              selection={selection}
              softwarePackage={dto}
              onUpdateSoftwarePackageVersion={onUpdateSoftwarePackageVersion}
            />
          );
        }
      },
      {
        key: "softwarePackageType",
        name: "Software Type",
        minWidth: 100,
        maxWidth: 250,
        isResizable: true,
        onRender: (dto: RolloutPackageSoftwarePackageSummaryDto) => (
          <Text>{dto.softwarePackageType}</Text>
        )
      },
      {
        key: "softwarePublisher",
        name: "Software Publisher",
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        onRender: (dto: RolloutPackageSoftwarePackageSummaryDto) => (
          <Text>{dto.softwarePublisher}</Text>
        )
      }
    ];
  }, [onUpdateSoftwarePackageVersion, selections]);

  return (
    <ScrollablePane styles={{ root: { height: 500, position: "relative" } }}>
      <ShimmeredDetailsList
        shimmerLines={5}
        columns={columns}
        onShouldVirtualize={() => true}
        onRenderRow={row => (row ? <DetailsRow {...row} /> : null)}
        detailsListStyles={{
          contentWrapper: {
            ":hover": {
              background: theme.palette.neutralQuaternaryAlt
            }
          }
        }}
        {...props}
      />
    </ScrollablePane>
  );
};
