import React from "react";

import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { useDeploymentRings } from "@libs/api/gateways/field/field-ops-gateway.hooks";

import { CustomBreadcrumb } from "./CustomBreadcrumb";

interface DeploymentRingBreadcrumbProps {
  deploymentRingId: string;
}

export const DeploymentRingBreadcrumb: React.FC<DeploymentRingBreadcrumbProps> = ({
  deploymentRingId
}) => {
  const deploymentRingsQuery = useDeploymentRings();

  return (
    <QueryStateIndicator {...deploymentRingsQuery}>
      {rings => {
        const ring = rings.find(x => x.id === deploymentRingId);

        return (
          <CustomBreadcrumb>
            {ring?.displayName ?? ring?.id ?? "..."}
          </CustomBreadcrumb>
        );
      }}
    </QueryStateIndicator>
  );
};
