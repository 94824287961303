import React, { useState } from "react";

import {
  confirm,
  IconButton,
  mergeStyleSets,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { dangerButtonStyles } from "@components/buttons/DangerButton";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { FieldDeviceAuthAction } from "@libs/api/gateways/field/field-ops-gateway.dtos";
import { useDeleteFieldDeviceAuthActions } from "@libs/api/gateways/field/field-ops-gateway.hooks";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";

import { AuthActionListItemDetails } from "./AuthActionListItemDetails";
import { EditAuthActionDialog } from "./EditAuthActionDialog";

interface AuthActionListItemProps {
  authAction: FieldDeviceAuthAction;
}

export const AuthActionListItem: React.FC<AuthActionListItemProps> = ({
  authAction
}) => {
  const theme = useTheme();
  const [open, setOpen] = useState<boolean>(false);
  const { mutateAsync: deleteAuthAction } = useDeleteFieldDeviceAuthActions(
    authAction.fieldDeviceId
  );

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltFieldManagementWrite
  );

  if (!authAction) {
    return null;
  }

  const onDeleteAction = async () => {
    const isConfirmed = await confirm({
      confirmButtonProps: {
        text: "Delete"
      },
      cancelButtonProps: {
        text: "Cancel"
      },
      dialogContentProps: {
        title: `Delete this ${authAction.deviceAction} action?`,
        subText:
          "This action has not been executed. Are you sure you want to delete this action?"
      }
    });

    if (isConfirmed) {
      await deleteAuthAction(authAction.id);
    }
  };

  return (
    <Stack
      styles={{
        root: {
          "& .RolloutListItemActions": {
            opacity: 0
          },
          "&:hover .RolloutListItemActions": {
            opacity: 1
          },
          minWidth: 660
        }
      }}
    >
      <EditAuthActionDialog
        onDismiss={() => setOpen(false)}
        authAction={authAction}
        hidden={!open}
      />
      <Stack
        tokens={{ childrenGap: theme.spacing.s1 }}
        styles={{
          root: {
            borderColor: theme.semanticColors.variantBorder,
            borderWidth: 1,
            borderStyle: "solid",
            padding: theme.spacing.s1
          }
        }}
      >
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
          tokens={{ childrenGap: 8 }}
        >
          <AuthActionListItemDetails authAction={authAction} />

          <Stack
            tokens={{ childrenGap: theme.spacing.s2 }}
            className="RolloutListItemActions"
          >
            <IconButton
              onClick={() => setOpen(true)} // Edit Auth Action
              iconProps={{
                iconName: "Edit"
              }}
              styles={{
                root: {
                  background: "transparent"
                }
              }}
              disabled={authAction.runDate !== undefined}
              title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
            />
            <IconButton
              onClick={onDeleteAction} // Delete Auth Action
              styles={mergeStyleSets(dangerButtonStyles(theme), {
                root: {
                  background: "transparent"
                }
              })}
              iconProps={{
                iconName: "Cancel"
              }}
              disabled={authAction.runDate !== undefined || !hasWritePermission}
              title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
