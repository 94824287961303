import { DateTime } from "@bps/utils";
import { LicenceDto } from "@libs/api/gateways/plt/plt-gateway.dtos";

export interface LicenceItem
  extends Pick<
    LicenceDto,
    | "id"
    | "licenceTypeCode"
    | "userId"
    | "username"
    | "isInactive"
    | "doNotRenew"
    | "productId"
    | "source"
  > {
  expiryDate: DateTime;
  updatedDate?: DateTime;
  status: "Valid" | "Invalid";
}

export const NEW_LICENCE_TEXT = "Create licence";
