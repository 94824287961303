import React, { FunctionComponent, useMemo } from "react";

import { Stack } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { FormDatePicker } from "@components/form/fields/FormDatePicker";
import { pinLengthSchema } from "modules/_shared-blocks/FormPin.validator";
import { FormTextField } from "@components/form/fields/FormTextField";
import { Form } from "@components/form/Form";
import { Validator } from "@components/form/validation/Validator";
import {
  useAddRedirectLink,
  useUpdatedRedirectLink
} from "@libs/api/gateways/plt-redirect/plt-redirect-gateway.hooks";

import {
  AddRedirectLinkFormProps,
  RedirectLinkFormValues
} from "./add-redirect-link-dialog.types";
import { ValidationMessages } from "@libs/validation/validation-messages.enum";
import { FormDropdown } from "@components/form/fields/FormDropdown";
import { FormSpinNumberInput } from "@components/form/fields/FormSpinNumberInput";
import { RedirectLinkField } from "./RedirectLinkField";
import { pinConfirmation } from "modules/_shared-blocks/pin-confirmation";

export enum Protocols {
  Http = "http",
  Https = "https"
}
const SPLIT_URL = "://";

const validator = new Validator<RedirectLinkFormValues>();

export const AddRedirectLinkForm: FunctionComponent<AddRedirectLinkFormProps> = ({
  onDismiss,
  redirectLink
}) => {
  const isEdit = !!redirectLink;

  const {
    error: addError,
    mutateAsync: addRedirectLink
  } = useAddRedirectLink();

  const {
    error: editError,
    mutateAsync: editRedirectLink
  } = useUpdatedRedirectLink();

  const defaultValues: RedirectLinkFormValues = useMemo(() => {
    if (isEdit && redirectLink) {
      const splitRedirectUrl = (redirectLink.redirectUrl ?? "")?.split(
        SPLIT_URL
      );

      const hasNoProtocol = splitRedirectUrl?.length === 1;
      return {
        redirectUrl: hasNoProtocol ? splitRedirectUrl[0] : splitRedirectUrl[1],
        redirectUrlScheme: !hasNoProtocol ? splitRedirectUrl[0] : undefined,
        notAfterUtc: redirectLink.notAfterUtc
          ? DateTime.fromISO(redirectLink.notAfterUtc).toJSDate()
          : undefined,
        notBeforeUtc: redirectLink.notBeforeUtc
          ? DateTime.fromISO(redirectLink.notBeforeUtc).toJSDate()
          : undefined
      };
    }
    return {
      redirectUrl: "",
      redirectUrlScheme: Protocols.Https,
      notAfterUtc: new Date(),
      notBeforeUtc: undefined,
      suffixLength: 12,
      prefix: "",
      pin: "",
      usePin: false
    };
  }, [isEdit, redirectLink]);

  const onSubmit = async (values: RedirectLinkFormValues) => {
    const { notAfterUtc, notBeforeUtc, ...restValues } = values;
    const convertedDates = {
      notBeforeUtc: notBeforeUtc ? notBeforeUtc.toISOString() : undefined,
      notAfterUtc: notAfterUtc ? notAfterUtc.toISOString() : undefined
    };

    try {
      if (redirectLink) {
        const payload = { ...redirectLink, ...restValues, ...convertedDates };
        await editRedirectLink(payload);
      } else {
        if (values.prefix) {
          if (values.suffixLength) {
            const payload = {
              ...restValues,
              ...convertedDates,
              prefix: values.prefix,
              usePin: values.usePin
            };

            const data = await addRedirectLink(payload);
            if (data.pin) {
              const isConfirmed = await pinConfirmation(data.pin);

              if (isConfirmed) {
                return onDismiss();
              }
            }
          } else {
            throw Error("Suffix Length is required.");
          }
        } else {
          throw Error("Prefix is required.");
        }
      }
      onDismiss();
    } catch (e) {
      return e;
    }
  };

  return (
    <Form<RedirectLinkFormValues>
      onSubmit={onSubmit}
      validate={values => {
        return validator.validateWithParse(values, {
          redirectUrlScheme: validator
            .string()
            .required({ message: ValidationMessages.required }),
          redirectUrl: [
            validator
              .string()
              .required({ message: ValidationMessages.required }),
            validator.string().url({
              protocolValidationLevel: "forbidden",
              subdomainValidationLevel: "optional",
              message:
                "Invalid URL format: Please ensure your URL does not start with 'http://' or 'https://'. Only plain URLs are allowed."
            })
          ],
          prefix: validator.custom().predicate({
            when: () => !isEdit,
            then: validator.string().required()
          }),
          suffixLength: validator.custom().predicate({
            when: () => !isEdit,
            then: validator.number().greaterThanOrEqual(0)
          }),
          notAfterUtc: validator.date().required(),
          pin: validator
            .custom()
            .predicate({ when: () => !isEdit, then: pinLengthSchema })
        });
      }}
      defaultValues={defaultValues}
      error={addError ?? editError}
    >
      <Stack
        horizontal
        tokens={{ childrenGap: 8 }}
        horizontalAlign="space-between"
      >
        <FormDropdown
          options={[
            { key: Protocols.Https, text: Protocols.Https },
            { key: Protocols.Http, text: Protocols.Http }
          ]}
          name="redirectUrlScheme"
          label="Schema"
          required
          fieldItemStyles={{ root: { minWidth: "35%" } }}
        />
        <FormTextField
          name="redirectUrl"
          label="Redirect URL"
          required
          fieldItemStyles={{ root: { flexGrow: 1 } }}
        />
      </Stack>

      {!isEdit && (
        <Stack
          horizontal
          tokens={{ childrenGap: 8 }}
          horizontalAlign="space-between"
        >
          <FormSpinNumberInput
            name="suffixLength"
            label="Suffix length"
            required
            fieldItemStyles={{ root: { width: "35%" } }}
          />
          <FormTextField
            name="prefix"
            label="Prefix"
            required
            fieldItemStyles={{ root: { flexGrow: 1 } }}
          />
        </Stack>
      )}

      <Stack horizontal tokens={{ childrenGap: 8 }}>
        <FormDatePicker name="notBeforeUtc" label="Date before" />
        <FormDatePicker name="notAfterUtc" label="Date after" required />
      </Stack>
      {!isEdit && <RedirectLinkField />}
    </Form>
  );
};
